
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement('div', { 'className': 'cm_search-by-ymmt__search-page' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '7050'
                }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '10350'
                }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1568'
            }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1982'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '2236'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2554'
            }, _createElement('div', {}, window.Convermax.isSearchByYMMTPage ? [_createElement('div', { 'key': '26401' }, '\n      No results were found\n      ', this.vehicleString && !this.query ? [
                    '\n        for your ',
                    _createElement('b', { 'key': '27411' }, '\'', this.vehicleString, '\'.')
                ] : null, this.query ? [
                    ' for the entered VIN number ',
                    _createElement('b', { 'key': '28881' }, '\'', this.query, '\'.')
                ] : null)] : null, !window.Convermax.isSearchByYMMTPage ? [_createElement('div', { 'key': '30241' }, '\n      No results were found for your\n      ', this.query ? [
                    ' query ',
                    _createElement('b', { 'key': '31351' }, '\'', this.query, '\'.')
                ] : null, !this.query ? [
                    ' query ',
                    _createElement('b', { 'key': '32261' }, '\'', this.search, '\'.')
                ] : null)] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3354'
            }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3573'
            }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '3817'
            }, 'Your search request failed to process\n', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '39680' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '40550' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '41380' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '39334' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [_createElement('aside', {
            'className': 'cm_side-panel' + (!window.document.body.classList.contains('page--category') ? ' page-sidebar category-sidebar' : ''),
            'key': '80640'
        }, _createElement('div', { 'className': 'sidebar-box' }, window.location.pathname === '/year-model-search/' ? [[this.SearchVehicleWidget_sidepanel(function () {
                    function repeatSelects1(selects, selectsIndex) {
                        return [selects(function () {
                                function repeatEntry1(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry2(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry3(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry4(entry, index) {
                                    return _createElement('div', {
                                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                        'key': entry.value,
                                        'onClick': () => this.onChange(entry.term)
                                    }, this.showCheckboxes ? _createElement('input', {
                                        'type': 'checkbox',
                                        'readOnly': true,
                                        'checked': entry.selected,
                                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                        'key': '3573'
                                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                        'className': 'cm_dropdown_hitCount',
                                        'key': '3775'
                                    }, entry.hitCount) : null);
                                }
                                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                    'key': '39'
                                }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                    'disabled': this.disabled,
                                    'aria-label': this.title
                                }, this.useNativeDropdown ? [
                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                        'value': '',
                                        'key': 'null-option'
                                    }, '\n          ', '', this.title, '\n        ') : null,
                                    this.loading ? _createElement('option', {
                                        'key': 'loading-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '7801'
                                        }, 'Popular Makes'),
                                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '7804'
                                        }, 'All Makes'),
                                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                    ] : null,
                                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                ] : null, !this.useNativeDropdown ? _createElement('option', {
                                    'key': '_current',
                                    'value': '_current'
                                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                        'aria-label': this.title,
                                        'key': '2869'
                                    },
                                    !this.hideNullOption ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'onClick': () => this.onChange('')
                                    }, '\n      ', '', this.title, '\n    ') : null,
                                    this.loading ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                ]) : null);
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__side-panel cm_SearchVehicleWidget_sidepanel' }, this.template === 'active' ? _createElement('div', {
                        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                        'key': '103'
                    }, _createElement('h3', {}, 'Search by Year, Make, Model, and Trim'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button',
                        'onClick': this.selectVehicle,
                        'disabled': !this.allowToSetVehicle
                    }, '\n                Search\n              '), _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button',
                        'onClick': this.discardVehicle,
                        'disabled': !this.allowToDiscardVehicle
                    }, '\n                Clear\n              ')))) : null, _createElement('p', { 'className': 'cm_vehicle-widget_not-listed' }, 'Vehicle not listed? ', _createElement('a', { 'href': '/contact-us/' }, 'Click Here')));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]] : null, window.location.pathname !== '/year-model-search/' ? [
            _createElement('h3', { 'key': '159871' }, 'Search by Year, Make, Model, and Trim'),
            _createElement('a', {
                'className': 'button',
                'href': '/year-model-search/',
                'key': '159873'
            }, 'YEAR/MAKE/MODEL/TRIM')
        ] : null), _createElement('div', { 'className': 'cm_side-panel__search-by-size sidebar-box' }, _createElement('h3', {}, 'Search by Size and other specifications'), _createElement('p', {}, 'Search by Size, Style, and more'), _createElement('a', {
            'className': 'button',
            'href': '/custom-search/'
        }, 'Custom Search')), _createElement('div', { 'className': 'cm_side-panel__search-by-part-number sidebar-box' }, _createElement('h3', {}, 'Search by Part Number'), _createElement('p', {}, 'Search using the OEM part number printed on your OE lift'), [this.PartNumberSearchBox(function () {
                return _createElement('div', { 'className': 'cm_search-box-root__part-number cm_search-box-root__side-panel cm_PartNumberSearchBox' }, _createElement('form', { 'onSubmit': this.preventFormSubmission }, _createElement('div', { 'className': 'cm_search-box_form-container' }, [this.searchInput(function () {
                        return _createElement('div', { 'className': 'form-input cm_searchInput' }, 'Enter Part Number');
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })], _createElement('span', { 'className': 'cm_search-box_clear-container' }, this.inputNotEmpty ? _createElement('span', {
                    'className': 'cm_search-box_clear',
                    'onClick': this.clearInput,
                    'key': '531'
                }, '\n              ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n            ') : null)), _createElement('button', {
                    'data-cm-role': 'add-query',
                    'className': 'button'
                }, 'Search')));
            }, {
                widgetName: 'undefined',
                items: undefined
            })], _createElement('p', { 'className': 'sidebar-box-info' }, _createElement('small', {}, _createElement('b', {}, 'Example:'), ' F85-278-406A-11AA\n        ', _createElement('br', {}), _createElement('b', {}, 'Enter Above:'), ' F85278406A11AA\n      '))), _createElement('div', { 'className': 'sidebar-box sidebar-box-transparent' }, _createElement('h3', {}, _createElement('i', { 'className': 'fa fa-exclamation-circle' }), ' Please Note'), '\n    Items sold on our site are sold individually unless otherwise noted. Images are to illustrate front and\n    rear of supports, please purchase the quantity needed in your order. Thank you!', _createElement('br', {}), _createElement('br', {}), _createElement('h3', {}, 'Still can\'t find your lift support?'), _createElement('a', { 'href': '/contact-us/' }, 'Click here to contact us'), ' and we\'ll find it for you and please allow 24 business\n    hours for reply.\n  '))], [_createElement('div', {
            'id': 'itemsBlock',
            'className': 'page-content cm_main-content',
            'key': '186500'
        }, [this.SearchVehicleWidget_label(function () {
                return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__label cm_SearchVehicleWidget_label' }, this.template === 'locked' ? _createElement('div', {
                    'className': 'cm_vehicle-widget_label cmTemplate_locked',
                    'key': '90'
                }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, this.vehicleString)) : null);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], _createElement('p', { 'className': 'results-info' }, '\n    Items ', _createElement('b', {}, this.pageFrom), ' to ', _createElement('b', {}, this.pageTo), ' of a total ', _createElement('b', {}, this.totalHits)), [this.SearchResult(function () {
                function repeatItems1(items, itemsIndex) {
                    return [items(function () {
                            function mergeProps(inline, external) {
                                var res = Object.assign({}, inline, external);
                                if (inline.hasOwnProperty('style')) {
                                    res.style = _.defaults(res.style, inline.style);
                                }
                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                    res.className = external.className + ' ' + inline.className;
                                }
                                return res;
                            }
                            function repeatI2(groupTitle, kitTitle, kitItem, lastGroupItem, kitSubmit, i, iIndex) {
                                return _createElement('span', {
                                    'key': i,
                                    'className': 'icon ' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'icon--ratingEmpty' : this.review_average - i < 0.75 ? 'icon--ratingHalf' : 'icon--ratingFull')
                                }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-star' })));
                            }
                            function onSubmit3(groupTitle, kitTitle, kitItem, lastGroupItem, kitSubmit, e) {
                                {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }
                            function onClick4(groupTitle, kitTitle, kitItem, lastGroupItem, kitSubmit, e) {
                                window.Convermax.addToCart(e);
                            }
                            function onClick5(groupTitle, kitTitle, kitItem, lastGroupItem, kitSubmit) {
                                window.Convermax.addKitToCart(this.kit_info);
                            }
                            function scopeGroupTitleKitTitleKitItemLastGroupItemKitSubmit6() {
                                var groupTitle = this.group_title;
                                var kitTitle = this.kit_title;
                                var kitItem = this.kit_item;
                                var lastGroupItem = this.last_group_item && !kitItem;
                                var kitSubmit = this.kit_submit;
                                return _createElement('div', { 'className': (groupTitle || kitTitle ? 'category-info group' : 'category-product') + (lastGroupItem || kitSubmit ? ' listItem-last-group-item' : '') }, groupTitle || kitTitle ? _createElement('h2', { 'key': '373' }, _createElement('div', { 'className': 'item-location' }, groupTitle ? this.location : 'Lift Supports Depot Kit'), groupTitle && this.notes ? _createElement('span', {
                                    'className': 'item-notes',
                                    'key': '518'
                                }, this.notes) : null) : null, !groupTitle && !kitTitle && !kitSubmit ? [
                                    _createElement('div', {
                                        'className': 'title-container',
                                        'key': '6151'
                                    }, _createElement('h4', { 'className': 'listItem-title' }, _createElement('a', Object.assign({}, { 'href': this.custom_url }, { dangerouslySetInnerHTML: { __html: this.name_search_by_ymmt } }))), kitItem && this.product_notes ? _createElement('p', {
                                        'className': 'listItem-subtitle listItem-notes',
                                        'key': '889'
                                    }, _createElement('i', { 'className': 'fa fa-info-circle' }), 'Notes:\n        ', _createElement('span', mergeProps({ 'className': 'listItem-notes' }, { dangerouslySetInnerHTML: { __html: this.product_notes } }))) : null, this.sku ? _createElement('p', {
                                        'className': 'listItem-subtitle',
                                        'key': '1149'
                                    }, '\n        Sku: ', _createElement('span', mergeProps({ 'className': 'listItem-sku' }, { dangerouslySetInnerHTML: { __html: this.sku } }))) : null),
                                    _createElement('div', {
                                        'className': 'info-containers',
                                        'key': '6153'
                                    }, _createElement('div', { 'className': 'left-container' }, _createElement('div', {
                                        'className': 'card-text',
                                        'data-test-info-type': 'price'
                                    }, _createElement('div', { 'className': 'productView-rating' }, [_createElement.apply(this, [
                                            'div',
                                            {
                                                'className': 'productView-rating',
                                                'key': '15030'
                                            },
                                            _map([
                                                0,
                                                1,
                                                2,
                                                3,
                                                4
                                            ], repeatI2.bind(this, groupTitle, kitTitle, kitItem, lastGroupItem, kitSubmit)),
                                            _createElement('span', { 'className': 'productView-reviewLink' }, !this.review_count ? ['(No reviews yet)'] : null, this.review_count ? [
                                                '(',
                                                this.review_count,
                                                ' reviews)'
                                            ] : null)
                                        ])])), _createElement('div', { 'className': 'price-section price-section--withoutTax' }, this.retail_price > this.price ? _createElement('span', {
                                        'className': 'price price--rrp',
                                        'key': '2197'
                                    }, '\n            ', this.formatPrice(this.retail_price), '\n          ') : null, _createElement('span', { 'className': 'price price--withoutTax' }, this.formatPrice(this.price), ' ', this.Quantity_Sold_As)), _createElement('p', { 'className': 'listItem-shipping' }, this.availability_description?.includes('Express shipping') ? _createElement('img', {
                                        'className': 'shipping-icon',
                                        'src': '/product_images/uploaded_images/free-shipping.png',
                                        'alt': 'Express shipping icon',
                                        'key': '2512'
                                    }) : null, this.availability_description?.includes('Usually ships within 24 hours') ? _createElement('img', {
                                        'className': 'shipping-icon',
                                        'src': 'https://portal.liftsupportsdepot.com/images/mini/24icon.png',
                                        'alt': '24 hours shipping icon',
                                        'key': '2713'
                                    }) : null, this.availability_description?.includes('Will ship on or before') ? _createElement('img', {
                                        'className': 'shipping-icon',
                                        'src': 'https://portal.liftsupportsdepot.com/images/mini/calendaricon.png',
                                        'alt': 'Shipping date icon',
                                        'key': '2938'
                                    }) : null, _createElement('span', { dangerouslySetInnerHTML: { __html: this.availability_description } }))), _createElement('div', { 'className': 'right-container' }, this.brand_name ? _createElement('div', {
                                        'className': 'manufacturer-container',
                                        'key': '3316'
                                    }, _createElement('div', { 'className': 'manufacturer-title' }, 'Manufactured By:'), this.brand_image ? _createElement('img', {
                                        'className': 'manufacturer-image',
                                        'src': this.brand_image,
                                        'alt': 'Logo of ' + this.brand_name,
                                        'key': '3452'
                                    }) : null, !this.brand_image ? _createElement('div', mergeProps({
                                        'className': 'listItem-brand-name',
                                        'key': '3578'
                                    }, { dangerouslySetInnerHTML: { __html: this.brand_name } })) : null) : null, !kitItem ? _createElement('form', {
                                        'className': 'listItem-buttons',
                                        'action': '/cart.php',
                                        'onSubmit': onSubmit3.bind(this, groupTitle, kitTitle, kitItem, lastGroupItem, kitSubmit),
                                        'key': '3728'
                                    }, !this.has_options ? [
                                        _createElement('input', {
                                            'type': 'hidden',
                                            'name': 'action',
                                            'value': 'add',
                                            'key': '38721'
                                        }),
                                        _createElement('input', {
                                            'type': 'hidden',
                                            'name': 'product_id',
                                            'value': this.id,
                                            'key': '38723'
                                        }),
                                        _createElement('label', { 'key': '38725' }, 'Qty:'),
                                        _createElement('input', {
                                            'className': 'form-input form-input--incrementTotal',
                                            'id': 'qty[]',
                                            'name': 'qty[]',
                                            'type': 'tel',
                                            'defaultValue': this.Custom_Qty ?? 1,
                                            'data-quantity-min': '0',
                                            'data-quantity-max': '0',
                                            'min': '1',
                                            'pattern': '[0-9]*',
                                            'aria-live': 'polite',
                                            'key': '38727'
                                        }),
                                        _createElement('input', {
                                            'id': 'form-action-addToCart',
                                            'className': 'button button--primary add-to-cart',
                                            'type': 'submit',
                                            'value': this.availability === 'preorder' ? `Pre-Order Now` : `Add to Cart`,
                                            'onClick': onClick4.bind(this, groupTitle, kitTitle, kitItem, lastGroupItem, kitSubmit),
                                            'key': '38729'
                                        })
                                    ] : null, _createElement('a', {
                                        'className': 'button',
                                        'href': this.custom_url
                                    }, 'Learn More')) : null))
                                ] : null, kitSubmit ? _createElement('div', {
                                    'className': 'kit-submit-container',
                                    'key': '4696'
                                }, _createElement('div', { 'className': 'price-section price-section--withoutTax' }, _createElement('div', {}, 'Complete Kit Price'), _createElement('div', { 'className': 'price-container' }, this.retail_price > this.price ? _createElement('span', {
                                    'className': 'price price--rrp',
                                    'key': '4887'
                                }, '\n          ', this.formatPrice(this.retail_price), '\n        ') : null, _createElement('span', { 'className': 'price price--withoutTax' }, this.formatPrice(this.price), ' ', this.Quantity_Sold_As))), _createElement('div', { 'className': 'listItem-buttons' }, _createElement('input', {
                                    'id': 'form-action-addToCart',
                                    'className': 'button button--primary add-to-cart',
                                    'type': 'submit',
                                    'value': 'Add Complete Kit to Cart',
                                    'onClick': onClick5.bind(this, groupTitle, kitTitle, kitItem, lastGroupItem, kitSubmit)
                                }))) : null);
                            }
                            return scopeGroupTitleKitTitleKitItemLastGroupItemKitSubmit6.apply(this, []);
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'category-group cmRepeater_items' },
                    _map(this.items, repeatItems1.bind(this))
                ]));
            }, {
                widgetName: 'results',
                items: undefined
            })], !window.Convermax.isVehicleSelected() ? [[this.pagination(function () {
                    function repeatButtons1(buttons, buttonsIndex) {
                        return [buttons(function () {
                                return this.template === 'prev' ? _createElement('div', { 'className': 'cmTemplate_prev' }) : this.template === 'next' ? _createElement('div', { 'className': 'cmTemplate_next' }) : this.template === 'page' ? _createElement('li', { 'className': 'pagination-item' + (this.isActive ? ' pagination-item--current' : '') + ' cmTemplate_page' }, this.isActive ? _createElement('a', {
                                    'href': this.pageLink,
                                    'className': 'pagination-link',
                                    'key': '119'
                                }, this.page) : null, !this.isActive ? _createElement('a', {
                                    'href': this.pageLink,
                                    'className': 'paging-link pagination-link',
                                    'key': '211'
                                }, this.page) : null) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_pagination' }, _createElement.apply(this, [
                        'ul',
                        { 'className': 'pagination nxt-address pagination-list cmRepeater_buttons' },
                        _map(this.buttons, repeatButtons1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]] : null)]);
}
        export const componentNames = ["cm:message","cm:filterInput","cm:SearchVehicleWidget_sidepanel","cm:searchInput","cm:PartNumberSearchBox","cm:SearchVehicleWidget_label","cm:SearchResult","cm:pagination"]